import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
} from '@mui/material';
import { Divider } from '@aws-amplify/ui-react';
import { v4 as uuidv4 } from 'uuid';
import { getSensorType } from '../../../services/localizationService';

StandardCreationDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  sensorTypes: PropTypes.array,
};

function StandardCreationDialog(props) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [operator, setOperator] = useState('');
  const [sensorType, setSensorType] = useState([]);

  const [value, setValue] = useState('');

  const handleSubmit = async () => {
    const input = {
      id: `${uuidv4()}:${sensorType}`,
      name,
      description,
      operator,
      sensorType,
      color: '#000000',
      icon: '',
      value: parseFloat(value),
    };
    console.log(input);
    props.handleSave(input);
    props.handleClose();
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>Create Rule</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            margin="dense"
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            margin="dense"
            label="Description"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Divider />
          <Typography variant="h7" sx={{ mb: 1 }}>
            Sensor Type
          </Typography>
          <br />
          <Select
            required
            title="Sensor Type"
            margin="dense"
            label="Sensor Type"
            fullWidth
            value={sensorType}
            onChange={(e) => setSensorType(e.target.value)}
          >
            {props.sensorTypes.map((sensorType) => (
              <MenuItem key={`create-standard-${sensorType}`} value={sensorType}>
                {getSensorType(sensorType)}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="h7" sx={{ mb: 1 }}>
            Operator
          </Typography>
          <Select
            required
            title="Operator"
            margin="dense"
            label="Oper"
            fullWidth
            value={operator}
            onChange={(e) => setOperator(e.target.value)}
          >
            <MenuItem value={'greater'}>Greater Than</MenuItem>
            <MenuItem value={'lesser'}>Less Than</MenuItem>
            <MenuItem value={'equal'}>Equal</MenuItem>
          </Select>
          <Typography variant="h7" sx={{ mb: 1 }}>
            Value
          </Typography>
          <TextField
            required
            margin="dense"
            label="Value"
            type="number"
            fullWidth
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StandardCreationDialog;
