import { toast } from 'react-toastify';
import { axiosInstance } from './common';

export const infoNotification = (message) => {
  toast.info(message);
};

export const errorNotification = (message) => {
  toast.error(message);
};

export const getNotificationsList = () => axiosInstance.get(`/notifications`).then((response) => response.data);

export const markNotificationRead = (notification) =>
  axiosInstance.post(`/notifications/markasread`).then((response) => response.data);
