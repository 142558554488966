import PropTypes from 'prop-types';
// @mui
import { Card, Typography, CardMedia, CardActionArea, CardContent, CardActions, Button } from '@mui/material';

StandardCard.propTypes = {
  standard: PropTypes.object,
  onEdit: PropTypes.func,
};

export default function StandardCard({ standard, onEdit }) {
  const { name, sensorType, description, operator, value } = standard;

  const getOperation = (oper) => {
    switch (oper) {
      case 'eq':
        return '=';
      case 'neq':
        return '!=';
      case 'greater':
      case 'gt':
        return '>';
      case 'gte':
        return '>=';
      case 'lesser':
      case 'lt':
        return '<';
      case 'lte':
        return '<=';
      default:
        return 'Unknown';
    }
  };

  const displayString = `${sensorType} Value ${getOperation(operator)} ${value}`;

  return (
    <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="https://media.istockphoto.com/id/926133126/vector/sensor-icon.jpg?s=612x612&w=0&k=20&c=Se6kDmEEFxH_PZeZLct6iWEVTUU6ETolGzU0uzTRozg="
          alt="sensor icon"
        />
      </CardActionArea>
      <CardContent>
        <Typography variant="h6" noWrap>
          {name}
        </Typography>
        <Typography variant="body2" noWrap>
          {description}
        </Typography>
        <Typography>{displayString}</Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() => {
            onEdit(standard);
          }}
        >
          Edit
        </Button>
      </CardActions>
    </Card>
  );
}
